
.filterContainer {
    margin-bottom: 20px !important;
    z-index: 1 !important;
    justify-content: space-between;
    
  }
  
  .bottom-para{
    background-color: #3688ec;
    color: #fff;
  }
.approved-height{
  height: 85vh !important;
}
 
  .paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  


  .tableContainer {
    margin-top: 115px;
    margin-left: 15px;
    margin-right: 35px;
    height: 77vh !important;
    /* padding-top: 85px; */
    /* overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: #1b67cc #e8ebf1;
    &::-webkit-scrollbar {
      width: 12px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #1b67cc;
    }
    
    &::-webkit-scrollbar-track {
      background-color: #e8ebf1;
    } */
    }
    
    
  
 

  