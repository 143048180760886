.app-insurer {
  font-family: "Quicksand SemiBold", "Quicksand", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  /* gap: 20px; */
  height: 70vh !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  /* background-color: rgba(32, 80, 170, 1); */
  /* padding: 40px !important; */
  /* margin-left: 20px !important; */
  /* padding-top: 25px !important; */
  width: auto !important;
  padding-top: 9vh;
}
.cust-acc{
  margin-bottom: 10px !important;
  margin-top: 5px !important;
}
.app-policyEnquiry .inputbox {
  margin: 2px !important;
  width: 100% !important;
}

.app-policyEnquiry .inputfull {
  width: 95% !important;
}

.input-container {
  color: white;
}

.poc {
  color: black;
}

.App {
  height: auto;

}

/* .tableContainer {
    overflow-y: scroll;
    max-width: 1200px;
    width: 100%;
  } */
/* Add this CSS to enable horizontal scrolling for the table */
.table-wrapper {
  overflow-x: auto;
  max-width: 200%;
  /* Adjust as needed */
}

.scroll {
  overflow-x: auto;
  width: max-content !important;
}

.table {
  width: 100%;
  min-width: 200px;
  /* Set a fixed width for the table */
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 12px;
}

.table thead {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
}

.firstBtn {
  color: #f99117 !important;
  font-family: "Quicksand Medium", "Quicksand", sans-serif !important;
  background-color: transparent !important;
  border-color: #ffffff !important;
  border-radius: 12px !important;
  font-size: 20px !important;
  width: 100px !important;
  margin-top: 0px !important;
}

.heading {
  color: #f99117 !important;
  font-size: 25px !important;
  text-decoration: underline !important;
  margin-left: 15px !important;
  margin-top: 30% !important;
  font-weight: 600;
}

.select-tag1 {
  width: 95%;
  /* width: 365px !important; */
  /* margin-left: 12px !important; */
  height: 30px !important;
  border-radius: 7px !important;
}

.form-card {
  padding: 0 !important;
  height: 100% !important;
  background-color: rgba(32, 80, 170, 1);
  color: white;
}

.scroll-x {
  overflow-y: scroll;
}

.logo4 {
  margin-bottom: none !important;
  margin-right: 40PX !important;
  margin-top: 20px !important;
}

.td {
  margin-left: 0 !important;
}

.heading-policy {
  color: #f99117 !important;
  font-size: 25px !important;
  text-decoration: underline !important;
  margin-left: 15px !important;
  margin-top: 4rem !important;
  font-weight: 600;
}