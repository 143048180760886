.support-card{
    color:  #f99117 !important;
}
.headings{
    color:  #fff !important;
    font-size: 25px !important;
    margin-left: 29px !important;
    text-decoration: underline !important;
    margin-top: 25px !important;
  }
.select-tagt{
    height: 28px !important;
    margin-top: 7px !important;
    margin-left: 10px !important;
    border-radius: 7px !important;
    width: 169px !important;
  }
  .dailog-container{
      width: 550px !important;
      height: 450px !important;
      padding: 10px !important;
      padding-left: 65px !important;
      padding-top: 25px !important;
  }
  .dailog-card{
    display: flex;
  }
  .dailog-head{
    color: #f99117 !important;
    margin-right: 15px !important;
  }
  .dailog-head4{
    color: #f99117 !important;
    margin-right: 35px !important;
  }
  .dailog-paragraph{
    color: #000 !important;
    padding-top: 3px !important;
    margin-left: 120px !important;
  }
  .dailog-paragraph0{
    color: #000 !important;
    padding-top: 3px !important;
    margin-left: 124px !important;
  }
  .dailog-paragraph1{
    color: #000 !important;
    padding-top: 3px !important;
    margin-left: 72px !important;
  }
  .dailog-paragraph2{
    color: #000 !important;
    padding-top: 3px !important;
    margin-left: 15px !important;
  }
  .dailog-paragraph3{
    color: #000 !important;
    padding-top: 3px !important;
    margin-left: 50px !important;
  }

  .dailog-card-button{
    text-align: center !important;  
  }
  .dailog-card-button1{
    text-align: right !important;
  }
  .close-button{
    font-family: "Quicksand SemiBold", "Quicksand", sans-serif !important;
    color: #f99117 !important;
    border-color: #000 !important;
    background-color: transparent !important;
    border-radius: 7px !important;
    padding: 5px !important;
    margin-top: 15px !important;
  }
  .cancel-button{
    font-family: "Quicksand SemiBold", "Quicksand", sans-serif !important;
    color: #f99117 !important;
    border-color: #000 !important;
    background-color: transparent !important;
    border-radius: 7px !important;
    padding: 5px !important;

    margin-bottom: 15px !important;
  }
  .td{
    margin-left: 120px !important;
    border-color: #fff !important;
    width: 265px !important;
  }
  .note{
    font-size: small !important;
  }
  .item{
    margin-top: 55px !important;
  }