.app-insurer {
  font-family: "Quicksand SemiBold", "Quicksand", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  /* gap: 20px; */
  height: 70vh !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  /* background-color: rgba(32, 80, 170, 1); */
  /* padding: 40px !important; */
  /* margin-left: 20px !important; */
  /* padding-top: 25px !important; */
  width: auto !important;
  padding-top: 9vh;
}
  
th {
  font-weight: 600 !important;
}

.username {
  padding: 5px;
}

.inputboxvu_insure {
  width: 95% !important;
  height: 38px !important;
  padding: 4px;

  .css-yrzdmv-control {
    width: 100% !important;
  }

  .css-15lwp1p-control {
    width: 100% !important;
  }
}

.css-1ex1afd-MuiTableCell-root {
  font-size: 12px !important;
}

.btn_insurer {
  color: #f99117 !important;
  font-family: "Quicksand Medium", "Quicksand", sans-serif !important;
  background-color: transparent !important;
  border-color: #ffffff !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  width: 90px !important;
  margin-top: 40px !important;
}

.note {
  color: white;
}

.tableContainer-insurer {
  width: 100% !important;
  position: relative !important;
  overflow-y: auto;
  max-width: 1856px;
  width: 100%;
}

.item-insurer {
  width: 100% !important;
  margin-top: 0px !important;
}

.form-card {
  height: 210px !important;
}

.logo {
  margin-bottom: none !important;
  margin-left: 40px !important;
}

.logo8 {
  margin-bottom: none !important;
  margin-right: 20px !important;
  margin-top: 40px !important;
}

.logo3 {
  margin-bottom: none !important;
  align-items: center !important;
  /* margin-top: 20px !important; */
}

.input-container {
  color: white;
  border-color: red !important;
  border-width: 2px !important;
}

.poc {
  color: black;
}

.App {
  height: auto;

}



.heading {
  color: #f99117 !important;
  font-size: 25px !important;
  text-decoration: underline !important;
  margin-left: 15px !important;
  margin-top: 30% !important;
  font-weight: 600;
}



.tableContainer {
  /* overflow-y: scroll; */
  max-width: 1856px;
  width: 100%;
}

/* Add this CSS to enable horizontal scrolling for the table */
.table-wrapper {
  overflow-x: auto;
  max-width: 200%;
}



.scroll {
  overflow-x: auto;
  /* width: max-content !important; */

}

.table {
  width: 100%;
  min-width: 200px;
  /* Set a fixed width for the table */
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 12px;
}

.table thead {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
}

.status-card {
  margin-top: 5px !important;
}

.status-container {
  height: 38px !important;
  border-color: none;
  border-radius: 5px;
}

/* .menu-item{
  margin-bottom: 12px !important;
} */
.export-button {
  align-self: center !important;
  align-items: center !important;
}

.request-button {
  padding: 5px !important;
  border-radius: 4px !important;
  font-size: xx-small !important;
}

.dropDown {
  margin-left: 10px !important;
  height: 35px !important;
  border-radius: 7px !important;
  width: 400px !important;
  padding: 7px !important;
}

.img-card {
  margin-right: 10px !important;
}

.inputboxvu {
  width: 400px !important;
  border-radius: 7px !important;
  height: 35px !important;
  padding: 7px !important;
  border-color: #fff !important;
}

.support-card {
  color: #f99117 !important;
  border-radius: 7px !important;
  width: 200px !important;
  text-align: left !important;
}