/* FilterComponent.css */

/* Style for the container */
.filterContainer {
    margin-bottom: 20px !important;
    z-index: 1 !important;
  }
  
  /* ... existing styles ... */
  
  .filterSelect {
    padding: 5px !important;
    margin-right: 10px !important;
    border: 1px solid #ccc !important;
    border-radius: 3px !important;
    display: inline-block !important;
    position: static !important;
    z-index: auto !important;
    margin-bottom: 9px;
    color: none;
 
  }
  
  /* Style for labels */
  .filterLabel {
    margin-right: 10px;
    font-weight: bold;
  }
  


.tableContainer {
    width: 100% !important; 
    margin-top: 20px;
    overflow-x: auto;
  }
  

  .bottom-para{
    background-color: #3688ec;
    color: #fff;
  }
 
  .paginationContainer {
    display: flex;
    justify-content: flex-end !important;
    margin-top: 20px;
  }
  

  