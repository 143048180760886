
.table-container {
    margin: 0 auto; 
  
  }
  .pan-header{
    margin: 0 auto; 
  }
  
 
  table {
    width: 75%;
    border-collapse: collapse;
    border: 1px solid #000000;
    margin: 0 auto; 
  }
  
  th, td {
    padding: 8px;
    border: 1px solid #000000;
    text-align: left;

  }
  .heading8{
    color: #f99117 !important;
    font-size: 25px !important;
    text-decoration: underline !important;
    margin-left: 40%;
    margin-top: 35px !important;
    font-weight: 600;
  }
  
  
  th {
    background-color: #f2f2f2;
  }
  
 
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
 
  tr:hover {
    background-color: #f9f9f9;
  }
  