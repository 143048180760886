.login_back {
  font-family: "Quicksand SemiBold", "Quicksand", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background-color: rgba(32, 80, 170, 1);
}

input[type="text"],
input[type="password"],
input[type="email"],
select {
  height: 30px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

input[type="submit"] {
  margin-top: 25px; 
  cursor: pointer;
  font-size: 15px;
  background: #031842;
  border: 1px solid #031842;
  color: #fff;
  padding: 6px 20px;
  width: 66%;
  height: 30%;
}

input[type="submit"]:hover {
  background: #6cf0c2;
}

input[type="text"]:hover {
  border-color: aqua;
}

/* input[type="text"]:hover {
    border-color:aqua;
  }
  input[type="password"]:hover {
    border-color:aqua;
  } */
input[type="email"]:hover {
  border-color: aqua;
}

.button-container {
  display: flex;
}

.login-form {
  background-color: rgba(32, 80, 179, 0.7);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* border-radius: 10px; */
}

.list-container {
  display: flex;
}

.app-container {
  padding-top: 240px !important;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin: 50px 0 0px 0;
  text-align: center;
  color: #f99117 !important;
  font-weight: 700;
}

.AppTitle {
  display: contents;

  font-size: 25px;
  margin: 10px 10px 25px 100px;
  text-align: center;
  color: #ffffff;
  background-color: rgba(32, 80, 170);
}

.input-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 10px;
  background-color: rgba(32, 80, 170);
}

label {
  display: inline;
  margin: 0px 0 0 0;
}

.Forgot {
  font-size: 13px;
  text-align: right;
  margin: 2px 7px 0 0;
}

.Newuser {
  text-align: right;
  margin: 5px;
}

.messages {
  display: flex;
  justify-content: center;
}

.buttonWidth {
  width: 400px !important;
  padding: 8px !important;
  border-radius: 12px !important;
  background-color: transparent !important;
  color: #f99117 !important;
  font-family: "Quicksand Medium", "Quicksand", sans-serif;
  font-weight: 600 !important;
  font-size: large !important;
  border-color: #fff !important;
}

.buttonWidth1 {
  width: 400px !important;
  padding: 8px !important;
  border-radius: 12px !important;
  background-color: transparent !important;
  color: #f99117 !important;
  font-family: "Quicksand Medium", "Quicksand", sans-serif;
  font-weight: 600 !important;
  font-size: large !important;
  border-color: gray !important;
  /* background-color: gray !important; */
}

.error {
  display: block;
  background-color: red;
  color: white;
  /* width: fit-content; */
  height: 50px;
  padding: 5px;
}

.success {
  display: block;
  background-color: lightblue;
  color: black;
  /* width: fit-content; */
  height: 50px;
  padding: 5px;
}

.register {
  text-align: right;
  margin-top: 15px;
}

.img-bima {
  width: 100% !important;
}

.logo {
  margin-top: 20px;
}
.anchor {
  text-decoration: none !important;
  color: #f99117 !important;
}
