
@import "~@fortawesome/fontawesome-free/css/all.css";



.model-card{
height: 98vh !important;
margin-top: 7px !important;
}


.contactlog{
  margin-bottom: 59px !important;
  width: 118% !important;
}


header {
    background-color: #1b67cc;
    color: white;
    padding: 20px;
    border: 10px;
    outline: #333;
    /* position: fixed; */
    width: 100%;
  }
  .header-s{
    background-color: #1b67cc;
    color: white;
    padding: 20px;
    border: 10px;
    outline: #333;
    position: fixed;
    width: 100%;
  }
  .nav-card{
    margin-top: 66px;
  }
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-content h1 {
    margin: 0;
    font-size: 24px;
  }
  
  .header-content a {
    color: #fff;
    text-decoration: none;
    padding: 8px 16px;
    border: 1px solid #fff;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
  
  .alert-message{
    margin-top: 100px !important;
    color: #ddd !important;
  }
  
  .proof-card{
    margin-top: 32px ;
  }
  .header-content a:hover {
    background-color: #fff;
    color: #333;
  }
  .font-size{
    font-size: small;
  }
  .report-scroll{
    overflow-y: scroll !important;
    height: 42vh;
    margin-left: 12px;
  }
  .sidebar {
    width: 300px;
    height: 100vh;
    /* margin-bottom: 35px !important; */
    font-size: 22px !important;
    background-color: rgb(232, 235, 241);
    color: #1b67cc;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Example box shadow */
    transition: width 0.3s ease, background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    overflow:scroll;
    scrollbar-width: thin;
  scrollbar-color: #1b67cc #f1ece8;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1b67cc;
  }

  &::-webkit-scrollbar-track {
    background-color: #e8f1ed;
  }
  }
 
  .toggle-btn {
    /* float:right; */
    margin-left: 90%;
    background-color: transparent;
    border: none;
    color: blue;
    font-size: 18px;
    cursor: pointer;
    margin-top: 14px;
  }
  .modal-body{
    height: 100vh;
  }
  

  .toggle-btn svg {
   
    width: 30px;
    height: 30px;
    fill: #1b67cc;
  
  }
  /* .collapsed.toggle-btn svg {
   
    
    fill: black;
  
  } */
  .bars{
    width: 30px;
    height: 30px;
    fill:#1b67cc;
    cursor: pointer;
    
  }

  nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  nav ul li {
    margin-bottom: 15px;
  }
  
  nav ul li a {
    
    color:#1b67cc;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  nav ul li a:hover {
    color:#1b67cc;
    font-weight: bold;
  }
  .linkicon{
    margin-right: 20px;
  }
  .nav-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    color:#1b67cc; 
    padding: 10px; 
    cursor: pointer;
  }
  .nav-item.active {
    color: #fff; 
    font-weight: bold; 
    background-color:#1b67cc ;
    border-radius: 5px;
  }

  .filtercontainer {
   
    display: 'flex'; 
    flex-Direction: 'row';
    justify-Content:'space-between'
    
  }
  .button-filter{
    display: flex;
    flex-direction: column;
  }
  .icon-text{
    font-size: 10px;
    text-align: center;
    margin-left: 25px;
  }
  .icon{
    font-size: 30px;
    margin-left: 20px;
    color:  #1b67cc;
    border-color: #000;
    border-radius: 5px;
  }
  [title]:hover:after {
    content: attr(title);
    position:initial;
    padding: 4px 8px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 10px;
    white-space: nowrap;
    z-index: 1;
  }
  [title]:hover {
    cursor: pointer;
  }
  .filterSelect{
  
    
       /* width: 50px; */
    padding: 6px;
    font-size: small;
    height: fit-content;
    font-size: small;
    margin-top: 10px;
  }
  
  /* Import Font Awesome styles */
@import "~@fortawesome/fontawesome-free/css/all.css";

.filtercontainer {
  /* margin-bottom: 20px !important; */
  display: 'flex'; 
  flex-Direction: 'row';
  justify-Content:'space-between'
  
}
.button-filter{
  display: flex;
  flex-direction: column;
}
.icon{
  font-size: 30px;
  margin-left: 20px;
  color:  #1b67cc;
}
[title]:hover:after {
  content: attr(title);
  position:initial;
  padding: 4px 8px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 10px;
  white-space: nowrap;
  z-index: 1;
}
[title]:hover {
  cursor: pointer;
}



.paginationContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.filterSelect{
 
  padding: 6px;
  font-size: small;
  height: fit-content;
  font-size: small;
  margin-top: 10px;
}


.collapsed-nav{
  background-color:#1b67cc;
  color: white;
}
.toggle-btn1 {
  /* float:center; */
  margin-left: 10%;
  background-color: transparent;
  border: none;
  color:#1b67cc;
  font-size: 30px;
  cursor: pointer;
  align-self: center;
}

.nav-report{
  height: 35vh;
}

/*table css*/
 .table-policy {
  width: 50%;
  table-layout: fixed;
  border-collapse: collapse;
  overflow: scroll;
  
  }
  
.table-policy tbody tr {
  cursor: pointer;
  } 
 .table-policy td {
  padding: 8px;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 0;
  border: 1px solid #ddd;
  }
  .data-scroll{
    overflow-x: scroll;
    height: 41vh;
  }
  .table thead th{
  background-color:#3688ec;
  
  font-size: small;
  color: white;
  text-align: center;
  
  width:150px;
  vertical-align: middle;
  }

  .home-icon{
    background-color: transparent;
    border-color: #fff;
  }
  .bottom-para{
    background-color: #3688ec;
    color: #fff;
  }
.custom-modal {
  width: 80% !important;
  max-width: 80% !important;
  margin: 0 auto !important;
 
}
/*loader */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 9999; 

 
}

.loading-text{
  color:#fff;
  font-size: larger;
}

.loader{
  display: block;
  position: relative;
  height: 32px;
  width: 150px;
  box-sizing: border-box;
  overflow: hidden;
  border: 2px solid  #1b67cc;
  border-radius: 20px;
}
.loader:before{
  content: '';
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #1b67cc;
  animation: ballbns 3s ease-in-out infinite;
}

@keyframes ballbns {
  0% {  
      left: 0; 
      transform: translateX(0%); 
      box-shadow: 
      -5px 0 0 -1px rgba(255, 61, 0, 0.9),
      -10px 0 0 -2px rgba(255, 61, 0, 0.8),
      -15px 0 0 -4px rgba(255, 61, 0, 0.6),
      -20px 0 0 -6px rgba(255, 61, 0, 0.4),
      -25px 0 0 -8px rgba(255, 61, 0, 0.2);
  }
  49% {  
      left: 100%; 
      transform: translateX(-100%); 
      box-shadow: 
      -5px 0 0 -1px rgba(255, 61, 0, 0.9),
      -10px 0 0 -2px rgba(255, 61, 0, 0.8),
      -15px 0 0 -4px rgba(255, 61, 0, 0.6),
      -20px 0 0 -6px rgba(255, 61, 0, 0.4),
      -25px 0 0 -8px rgba(255, 61, 0, 0.2);
  }
  51% {  
      left: 100%; 
      transform: translateX(-100%); 
      box-shadow: 
      5px 0 0 -1px rgba(255, 61, 0, 0.9),
      10px 0 0 -2px rgba(255, 61, 0, 0.8),
      15px 0 0 -4px rgba(255, 61, 0, 0.6),
      20px 0 0 -6px rgba(255, 61, 0, 0.4),
      25px 0 0 -8px rgba(255, 61, 0, 0.2);
  }
  100% {  
      left: 0; 
      transform: translateX(0%); 
      box-shadow: 
      5px 0 0 -1px rgba(255, 61, 0, 0.9),
      10px 0 0 -2px rgba(255, 61, 0, 0.8),
      15px 0 0 -4px rgba(255, 61, 0, 0.6),
      20px 0 0 -6px rgba(255, 61, 0, 0.4),
      25px 0 0 -8px rgba(255, 61, 0, 0.2);
  }
}  


/* Alerts */

.alert-success {
  /* width: 100px; */
  background-color: #d4edda;
  color: #155724;
  border-color: #c3e6cb;
  z-index: 9999999;
 
}


.alert-danger {
  /* width: 100px; */
  background-color: #f8d7da;
  color: #721c24;
  border-color: #f5c6cb;
  z-index: 9999999;

} 


.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1060;
}

.fixed-right {
  right: 0;
}

.m-3 {
  margin: 1rem;
}

.custom-toast {
  width: 300px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;
  margin: 16px; 
  opacity: 0;
  transition: opacity 0.3s ease-in-out; 
  height: 6% !important;
}

.custom-toast.show {
  opacity: 1;
}


.home-icon{
  color: #fff !important;
}
.sidebarcollapsed{
  padding-top: 103px;
}
.downloadimage{
  margin-top: 150px;
  width: 90vw;
}
.image-card{
  width: 100vh;
  align-self: center;
  margin-left: 150px;
}
.image-input{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap:50px;
  margin-left: 50px;
border: solid 2px ghostwhite;
width: 45vw;
}
.pdfproof{
  height: 70vh;
  align-self: center;
}

.react-datepicker__today-button {
  background-color: #007bff;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
}

.react-datepicker__today-button:hover {
  background-color: #0056b3;
  color: white;
}