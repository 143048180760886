body {
  background-color: #f8f9fd;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-family: serif;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.app-2 {
  font-family: "Quicksand SemiBold", "Quicksand", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background-color: rgba(32, 80, 179, 1);
  /* margin-top: 10px; */
  /* padding-top: 165px !important; */
  text-align: center !important;
}

.form-l {
  background-color: rgba(32, 80, 179, 1);
  /* background-color: #000; */
  /* // border-radius: 5px; */
  width: 30%;
  /* margin: -20% 0 0 0; */
  padding: 20px;
  margin-top: 10px !important;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* height: 600px; */

}

.form-body {
  text-align: left;
  padding: 20px 10px;

}

.form-body>* {
  padding: 5px;
}

.form__label {
  width: 40%;
  margin-left: 10px !important;
  color: #f99117;
  font-weight: 600;
}

.label_cls {
  color: #f99117;
  font-weight: 600;
  margin-top: 10px !important;
  /* margin-bottom: 10px !important; */
}
.label_cls_poc {
  color: #f99117;
  font-weight: 600;
  margin-top: 10px !important;
  /* margin-bottom: 10px !important; */
  margin-left: 4px !important;
}

.form_input {
  width: 60%;
  border-color: aqua;
}

.footer {
  text-align: center;
  border-color: #000 !important;
}


.register {
  text-align: right;
  margin-top: 10px;
}

.butt {
  margin-top: 25px;
  cursor: pointer;
  font-size: 15px;
  border: 1px solid #031842;
  color: #f99117 !important;
  padding: 6px 20px;
  width: 40% !important;
  height: 30%;
  font-family: "Quicksand Medium", "Quicksand", sans-serif !important;
  font-size: 25px !important;
  background-color: transparent !important;
  border-radius: 15px !important;
  border-color: #ffffff !important;


}

.oooo {
  height: 200px;
}

/* .centerImg {
    align-items: center;
  } */
.logo1 {
  margin-top: 200px;
  display: flex;
  align-items: center;
  /* margin-inline-start: 250px; */
  /* margin-left: 110px !important;
     */
}

.bima-logo {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.container {
  text-align: center !important;
  display: flex !important;
}

.input-captcha {
  width: 212px !important;
  text-align: center !important;
  border-color: #000 !important;
  border-radius: 7px !important;
  height: 30px !important;
  margin-right: 5px !important;
  margin-top: 30px !important;
}


.text-input {
  background-color: #fff !important;
  /* border-radius: 5px !important; */
  padding: 7px !important;
  border-color:  #E8F0FE  !important;
}

.col {
  display: flex !important;
}

.add {
  width: 100% !important;
  margin-bottom: 10px !important;
}
.add-pass{
  height: 30px !important;
  border-right-color: transparent !important;
  outline: none !important;
}

.img-icon{
  height: 25px !important;
  border:1px solid   #E8F0FE !important;
  background-color:  transparent !important;
  margin-top: 5px !important;
}
.input-icon-card{
  background-color: #E8F0FE !important;
  height: 32px !important;
  /* margin-top: 5px !important; */
  /* border:2px solid   #E8F0FE !important; */
  /* outline: #000 !important; */
  border-radius: 5px !important;
  /* border: #f99117 1px solid; */
}
.input-pass{
  background-color: red !important;
}
.reset-card{
  background-color: rgba(32, 80, 179, 1);
  height: 100vh !important;
  display: flex;
  justify-content: center;
  padding-top: 5% !important;

}
.reset-heading{
  color: #f99117 !important;
  margin-top: 5% !important;
  font-size: 30px !important;
}
.reset-pass{
  width: 100% !important;
  /* border-radius: 5px !important; */
  outline: none !important;
  border-right-color: transparent !important;
  outline: transparent !important;
 
  
}
.reset-label{
  color: #f99117 !important;
}
.btn-card{
  background-color: transparent;
  
  
  border-radius: 15px !important;
  display: flex ;
  justify-content: center;
 
}
.btn-submit{
  background-color: transparent;
  color: #f99117 !important;
  border-color: #fff !important;
  margin-top: 20px !important;
  border-radius: 5px !important;
  font-size: 20px !important;
}
.reset-desc{
  color: #fff;
  font-size: 14px !important;
  margin-top: 10px !important;
}
.error-para{
  margin-bottom: 5%;
  color: red;
}
.error-para-new1{
  margin-bottom: 5%;
  color: red;
}
.error-para-new2{
  margin-bottom: 5%;
  color: #7bd192;
}
.reset-icon-card{
  background-color: #fff !important;
  height: 32px !important;
  border-radius: 5px !important;
  border: #f99117 1px solid;
}
.reset-icon{
  margin-top: 4px !important;
}

/* .upper{
  text-transform: lowercase !important;
} */