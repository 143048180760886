.MenuPage {
  background-color: rgba(32, 80, 170, 1) !important;
  
}

.menu-item {
  margin-bottom: 35px !important;
  font-size: 22px !important;
  color: #0051ba !important;
  text-decoration: none !important;
}

.dropdown-item {
  color: red !important;
}

.logout-button {
  background-color: transparent !important;
  color: #f99117 !important;
  border-radius: 7px !important;
  font-size: larger !important;
  font-weight: bold !important;
  margin-left: 52px !important;
  border-color: #5585cc !important;
  margin-top: 60px !important;
  font-family: "Quicksand SemiBold", "Quicksand", sans-serif !important;
}

.dropdown-content {
  display: flex !important;
  flex-direction: column !important;
  cursor: alias;
  font-size: 20px !important;
  padding-bottom: 10px !important;
}

.head {
  color: #f99117 !important;
  font-size: 20px !important;
  border-radius: 7px !important;
  padding: 5px !important;
  width: 200px !important;
  text-align: left !important;
}

.link-text {
  margin-bottom: 10px !important;
}

.version {
  /* margin-left: 5px !important; */
  position: relative;
  top: 40px;
  font-size: 14px !important;
  left: 120px;
}

.logo-bima{
  margin-left: 45% !important;
}
.profile-card{
  /* border: 2px solid #fff !important;
  padding: 5px !important;
  border-radius: 5px !important; */
  display: flex !important;
  margin-top: 40px !important;
}