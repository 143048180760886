.app-insurer {
  font-family: "Quicksand SemiBold", "Quicksand", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  /* gap: 20px; */
  height: 70vh !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  /* background-color: rgba(32, 80, 170, 1); */
  /* padding: 40px !important; */
  /* margin-left: 20px !important; */
  /* padding-top: 25px !important; */
  width: auto !important;
  padding-top: 9vh;
}
  
.end-select{
height: 38px !important;
margin-top: 5px;

}
.input-container {
  color: white;
}
.head{
margin-top: 33% !important;
}
.poc {
  color: black;
}

.App {
  height: auto;

}
.username {
  padding: 5px;
}
.heading {
  color: #f99117 !important;
  font-size: 25px !important;
  text-decoration: underline !important;
  margin-left: 15px !important;
  margin-top: 30% !important;
  font-weight: 600;
}
.logom {
  margin-bottom: none !important;
  margin-right: 45px !important;
  margin-top: 20px !important;
}

/* .tableContainer {
    overflow-y: scroll;
    max-width: 1200px; 
    width: 100%;
  } */
/* Add this CSS to enable horizontal scrolling for the table */
.table-wrapper {
  overflow-x: auto;
  max-width: 200%;
  /* Adjust as needed */
}

.form-card1 {
  height: 240px !important;
}

.scroll {
  overflow-x: auto;
  width: max-content !important;
}

.table {
  width: 100%;
  min-width: 200px;
  /* Set a fixed width for the table */
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 12px;
}

.table thead {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
}

.select-tage {
  margin-left: 10px !important;
  /* width: 166px !important; */
  height: 30px !important;
  padding: 5px !important;
  border-radius: 5px !important;
  border-color: #fff !important;
  margin-bottom: 8px !important;
}

.inputbox {
  width: 315px !important;
  border-radius: 5px !important;
  border-color: #fff !important;
  margin-right: 13px !important;
}

.img-card {
  margin-right: 240px !important;
  align-items: center;
}

.td {
  margin-left: 120px !important;
}

.note {
  font-size: small !important;
  margin-top: 7px !important;
}
.dialog-btn{
  color: #f99117 !important;
  background-color: transparent;
  border: #f99117 1px solid;
  outline: #f99117 1px solid !important;
  font-size: 12px !important;
}
.reset-click{
  color: #fff !important;
  font-weight: bold;
  outline:  blue 1px solid !important;
  /* text-align: center !important; */
  margin-top: 7px !important;
  font-size: 12px !important;
  width: 20%;
  /* align-items: center !important; */
  align-self: center !important;
  background-color: blue !important;
  border-radius: 25px !important;
  /* padding: 10px !important; */
  width: 50% !important;
}
.dilog-text{
  color: red !important;
  font-size: 15px !important;
}
.dialog-card{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  text-align: center;

}
.dailog-raduis{
  border-radius: 20px !important;
}
.endos-rene-head{
  margin-top: 5%;
}
.table-head{
background-color: rgb(83, 95, 95)  !important;
font-weight: 600 !important;
border-bottom: 1px solid grey !important;
border-right: 1px solid  grey !important; 
border-left:1px solid  grey !important;
color: #fff !important;
}
.table-head-insu{
  background-color: antiquewhite !important;
  font-weight: 600 !important;
  border-bottom: 1px solid grey !important;
  border-right: 1px solid  grey !important; 
  border-left:1px solid  grey !important;
  }
  .table-head-insa{
    background-color: antiquewhite !important;
    font-weight: 600 !important;
    border-bottom: 1px solid grey !important;
    border-right: 1px solid  grey !important; 
    border-left:1px solid  grey !important;
    }
    .table-head-status{
      background-color: rgb(240, 240, 227) !important;
      font-weight: 600 !important;
      border-bottom: 1px solid grey !important;
      border-right: 1px solid  grey !important; 
      border-left:1px solid  grey !important;
      }
.table-head-card{
  background-color: rgb(83, 95, 95) !important;
  font-weight: bold !important;
  border-bottom: 1px solid grey !important;
  border-right: 1px solid  grey !important; 
  border-left:1px solid  grey !important;
  color: #fff !important;
  }

/* table , td , th {
    border-right: 1px solid  grey !important; 
    border-left:1px solid  grey !important;
  } */

  /* tr {
    border-bottom: 1px solid grey !important;
  }
    */
  .match-func{
    display: flex !important;
    justify-content: space-between !important;
  } 
  .match-btn{
    background-color: rgb(8, 136, 57) !important;
    color: #fff !important;
    border-radius: 7px !important;
    width: 45% !important;
  }
  .not-match-btn{
    background-color:rgb(252, 47, 47)!important;
    color: #fff !important;
    border-radius: 7px !important;
    width: 45% !important;
  }

  .submit-btn{
    background-color:rgb(93, 139, 209)!important;
    color: #fff !important;
    border-radius: 7px !important;
    width: 140px !important;
  }
  .match-func-final{
    display: flex !important;
    justify-content:center !important;
    padding: 20px !important;
  }
  .btn-card-table{
    display: flex !important;
    justify-content: center !important;
  } 