.bulk-card {
  /* background-color: rgba(32, 80, 179) !important; */
  height: 100vh;
  color: #fff;
  /* padding: 70px; */
}

.bulk-para {
  background-color: #000;
  color: #fff;
  width: 14%;
  border-radius: 5px;
  padding: 3px;
}
.policydata-select {
  width: 24vw;
  display: flex;
}
.policydata-select-select {
  width: 15vw;
  display: flex;
  margin-bottom: 5px !important;
}
.card-div {
  display: flex;
  margin-left: 25%;
  /* margin-bottom: 5px; */
  margin-top: 17px !important;
}
.card-div-lot {
  display: flex;
  margin-left: 25%;
  margin-bottom: 5px;
}
.label-bulk {
  margin-right: 40%;
  color: #f99117 !important;
}
.radio-btn {
  margin-right: 20px;
}
.card-div1 {
  display: flex;
}
.label-bulk-radio {
  margin-left: 6px;
  color: #f99117 !important;
}
.date {
  width: 190px !important;
  margin-left: 15px !important;
  border-radius: 5px;
}
.label-card {
  width: 50%;
}
/* .file{
    background-color: transparent;
    border-style: normal !important   ;
    border-color: red;
       border-width: 100;
} */
.card-container {
  border-width: 2px;
  padding-top: 15px;
  width: 96%;
  margin-left: 18px;
  /* margin-top: 140px; */
  height: 64vh;
}
.buttons {
  display: flex;
  /* width: 50% !important; */
  /* background-color: #fff; */
  justify-content: center;
}
/* .btn {
 
  color: #f99117 !important;
  font-family: "Quicksand Medium", "Quicksand", sans-serif !important;
  background-color: transparent !important;
  border-color: #fff !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  margin: 5px;
  width: 120px !important;
  
}  */

.btn_insurer_bulk {
  color: #f99117 !important;
  font-family: "Quicksand Medium", "Quicksand", sans-serif !important;
  background-color: transparent !important;
  border-color: #ffffff !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  width: 120px !important;
  margin-top: 40px !important;
  margin: 15px !important;
}
.paragraph {
  margin-bottom: 5px;
  text-align: center;
  color: #f99117 !important;
}
.policy-heading {
  padding-top: 4% !important;
  text-align: center;
  padding-bottom: 3%;
}
.heading1-bulk {
  color: #f99117 !important;
  font-size: 25px !important;
  text-decoration: underline !important;
  margin-left: 15px !important;
  margin-top: 5% !important;
  font-weight: 600;
}
.lotno {
  width: 190px !important;
  margin-left: 15px !important;
  border-radius: 5px;
}
.input-card {
  width: 65%;
  /* text-align: center !important; */
  display: flex;
  justify-content: flex-end !important;
}
.input-date-card {
  width: 46vw;
  text-align: center !important;
  display: flex;
  justify-content: flex-end !important;
  /* margin-left: 160px !important; */
  margin-bottom: 10px !important;
}
.lot-card {
  display: flex !important;
  justify-content: flex-end !important;
}
.label {
  margin-top: 12px;
}
.note-para {
  text-align: center;
  font-size: small !important;
  /* margin-top: 7px !important; */
}
.note-para-label {
  font-size: small !important;
  margin-top: 5px !important;
  margin-left: 5px;
}
.error-message {
  font-size: large !important;
  margin-top: 5px !important;
  margin-left: 5px;
  color: rgb(233, 29, 22) !important;
}

::placeholder {
  color: rgb(202, 194, 194);
  font-size: small;
}
.open-card {
  background-color: #fff !important;
  width: 30vw !important;
  height: 30vh;
  border-radius: 7px !important;
  text-align: center;
  padding: 45px;
  margin-top: 145px;
}
.model-para {
  color: #2e7d32 !important;
}
.model-card {
  display: flex !important;
  justify-content: center !important;

  /* background-color: #fff !important; */
}
.model-btn {
  border-radius: 4px !important;
  color: #f99117 !important;
  border-color: #000 !important;
}
.inputboxvu_insure1 {
  width: 95% !important;
  height: 38px !important;
  padding: 4px;

  .css-yrzdmv-control {
    width: 100% !important;
  }

  .css-15lwp1p-control {
    width: 100% !important;
  }
}
.input-text {
  width: 15vw !important;
  border-radius: 7px !important;
  border-color: #ddcdcd !important;
}
.input-card {
  display: flex;
  text-align: end;
  margin-left: 13% !important;
  margin-bottom: 5px !important;
}
.table-card {
  margin-top: 135px !important;
}
.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  margin-bottom: 11px !important;
}
