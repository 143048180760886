/* .app-1 {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  gap: 20px;
  height: 100vh !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  background-color: rgba(32, 80, 170, 1);
} */

.reload1 {
  color: #fff !important;
  background-color: black !important;
}

input[type="text"],
input[type="password"],
input[type="email"] {
  height: 0px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: none 0px 10px 0px;
}

input[type="submit"] {
  margin-top: 25px;
  cursor: pointer;
  font-size: 15px;
  background: #031842;
  border: 1px solid #031842;
  color: #fff;
  padding: 6px 20px;
  width: 66%;
  height: 30%;
}

input[type="date"] {
  width: 100%;
  margin: 5px 0 0 0px;
}

input[type="submit"]:hover {
  background: #6cf0c2;
}

input[type="text"]:hover {
  border-color: aqua;
}

/* input[type="text"]:hover {
  border-color:aqua;
}
input[type="password"]:hover {
  border-color:aqua;
} */
input[type="email"]:hover {
  border-color: aqua;
}

/* .button-container {
  display: flex;
} */

.login-form {
  background-color: rgba(32, 80, 179, 0.7);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* border-radius: 10px; */
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin: 0px 0 25px 0;
  text-align: center;
  color: #f99117 !important;
}

.AppTitle {
  display: contents;
  font-size: 25px;
  margin: 10px 10px 25px 100px;
  text-align: center;
  color: #ffffff;
  background-color: rgba(32, 80, 170);
  ;

}

.input-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 10px;
}

label {
  display: inline-block;
  margin: 0px 0 0 0;
}

.Forgot {
  font-size: 13px;
  text-align: right;
  margin: 2px 7px 0 0;
}

.Newuser {
  text-align: right;
  margin: 5px;
}

.messages {
  display: flex;
  justify-content: center;
}

.buttonWidth {
  width: 200px;
}

.error {
  display: block;
  background-color: red;
  color: white;
  width: fit-content;
  height: 50px;
  padding: 5px;
}

.success {
  display: block;
  background-color: lightblue;
  color: black;
  width: fit-content;
  height: 50px;
  padding: 5px;
}

.register {
  text-align: right;
  margin-top: 15px;
}

.pppp {
  background-color: rgba(32, 80, 179);
}

.img-bima {
  width: 100% !important;
}

.lable {
  display: inline-block;

}

.firstBtn {
  height: 40px;
  width: 100px;
  /* margin: 30px 0px 0px 55px; */
  margin-right: 10px !important;
  margin-top: 45px !important;
}

.firstBtn1 {
  height: 40px;
  width: 100px;
  /* margin: 30px 0px 0px 55px; */
  margin-right: 358px !important;
  margin-top: 45px !important;
}

.secondBtn {
  height: 40px;
  width: 100px;
  margin: 30px 0px 0px 65px;
}

.table-container {
  margin: 20px;
  width: 1300px !important;
  overflow-y: auto !important;
  /* margin-left: 40px !important; */
}

table {
  /* width: 50% !important; */
  border-collapse: collapse;
  background-color: white;
}


/* td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #c61111;
} */

th {
  background-color: #f2f2f2;
  font-weight: bold;
  padding: 10px;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f5f5f5;
}

.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 20px;
  left: 12px;
  top: 33px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #f0f0f3;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
/* .bm-cross-button {
  height: 50px;
  width: 50px;
} */

/* Color/shape of close button cross */
/* .bm-cross {
  background: #f99117 !important;
  height: 35px !important;
  margin-right: 235px !important;
} */

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  background-color: #ffffff !important;
  width: 20% !important;
  padding: 0px !important;
  top: 13vh;
}

/* .bm-menu-wrap .menu-container {
  position: fixed;
  right: inherit;
  z-index: 1100;
  top: 13vh;
  width: 300px;
  height: 100%;
  transition: all 0.5s ease 0s;
} */

.bm-bur

/* General sidebar styles */
.bm-menu {
  background: #b9bcc9;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;

}

/* Morph shape necessary with bubble or elastic */
/* .bm-morph-shape {
  fill: #373a47;
} */

/* Wrapper for item list */
.header_menu {
  margin-bottom: 10px !important;
  font-weight: 600;
}

.bm-item-list {
  color: #b8b7ad;
  /* padding: 3.8em; */
  /* background-color: rgba(245, 240, 240, 0.5); */
  width: 900px !important;
  padding-top: 25px !important;
  padding-left: 25px !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-bottom: 10px !important;
  text-decoration: none !important;
  font-weight: 600;
}

/* Styling of overlay */
/* .bm-overlay {
  background-color: rgba(32, 80, 179);
  width: 50%;  
} */

.menu-container {
  background-color: #faf5f5 !important;
}

.MenuPage {
  background-color: rgba(32, 80, 179) !important;
  /* height: 100vh !important; */
  width: auto !important;
  margin-top: none !important;
}

.menu-display {
  height: 100vh !important;
  background-color: rgba(32, 80, 179) !important;
}

.logo3 {
  display: flex;
  align-items: center !important;
  margin: auto !important;
}



.table-scroll {
  position: relative;
  max-width: 600px;
  margin: auto;
  overflow: hidden;
  border: 1px solid #000;
}

.table-wrap {
  width: 100%;
  overflow: auto;
}

.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  border: 1px solid #000;
  background: #fff;
  white-space: nowrap;
  vertical-align: top;
}

.table-scroll thead,
.table-scroll tfoot {
  background: #f9f9f9;
}

.clone {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.clone th,
.clone td {
  visibility: hidden
}

.clone td,
.clone th {
  border-color: transparent
}

.clone tbody th {
  visibility: visible;
  color: red;
}

.clone .fixed-side {
  border: 1px solid #000;
  background: #eee;
  visibility: visible;
}

.tableContainer {
  width: 100% !important;
  position: relative !important;
}

.td {
  margin-left: 120px !important;
}

.td5 {
  width: 317px !important;

}

/* styles.css or App.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 13vh;
  width: 100%;
  padding: 0 20px;
  position: fixed;
  z-index: 100;
  /* Adjust padding as needed */
  background-color: rgba(32, 80, 170, 1);
  color: #fff;
  border-bottom: 4px solid #f99117 !important;
  /* Add an orange border at the bottom */
  /* border-style: solid !important; */
}

.clickable-image {
  width: 30px;
  cursor: pointer;
  margin-left: 16px !important;
}


.clone thead,
.clone tfoot {
  background: transparent;
}


