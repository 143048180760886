.app-insurer {
  font-family: "Quicksand SemiBold", "Quicksand", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  /* gap: 20px; */
  height: 70vh !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  /* background-color: rgba(32, 80, 170, 1); */
  /* padding: 40px !important; */
  /* margin-left: 20px !important; */
  /* padding-top: 25px !important; */
  width: auto !important;
  padding-top: 9vh;
}
.loading {
  background-color: transparent !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  /* text-align: center !important; */
  padding-top: 40px !important;

}

.menupage-height {
  height: 56vh !important;
}

.poly-data {
  margin-top: -4px !important;
}

.input-container {
  color: white;
}

.poc {
  color: black;
}

.App {
  height: auto;
}

/* .tableContainer {
      overflow-y: scroll;
      max-width: 1200px; 
      
    } */
/* Add this CSS to enable horizontal scrolling for the table */
.table-wrapper {
  overflow-x: auto;
  max-width: 200%;
  /* Adjust as needed */
}

.scroll {
  overflow-x: auto;
  width: max-content !important;
}

.table {
  width: 100% !important;
  /* min-width: 200px;  */
  border-collapse: collapse;
}

.button-poli {
  text-align: center !important;
}

.table th,
.table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 12px;
}

.table thead {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;
}

.select-tagd {
  margin-left: 9px !important;
  /* width: 169px !important; */
  height: 30px !important;
  padding: 5px !important;
  border-radius: 7px !important;
  margin-bottom: 5px !important;
}

.item {
  width: 100% !important;
}

.form-card {

  height: 87vh !important;
}

.heading {
  color: #f99117 !important;
  font-size: 25px !important;
  text-decoration: underline !important;
  margin-left: 15px !important;
  margin-top: 30% !important;
  font-weight: 600;
}

.firstBtn {
  margin-left: none !important;
  margin-right: 50px !important;
}

.img-card {
  margin-right: 220px !important;
}

.logod {
  margin-bottom: none !important;
  margin-right: 10PX !important;
  margin-top: 20px !important;
}

.inputbox {
  border-color: #fff !important;
  margin-bottom: 9px !important;
}

.inputbox1 {
  border-color: #fff !important;
  margin-bottom: 2px !important;
}

.td {
  margin-left: 120px !important;
}

.note {
  font-size: small !important;
  margin-top: 7px !important;
}

.btn-style {
  color: #f99117 !important;
  font-family: "Quicksand Medium", "Quicksand", sans-serif !important;
  background-color: transparent !important;
  border-color: #ffffff !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  width: 90px !important;
}
.policy-data{
  margin-top: 35% !important;
}
.label_cls_poc {
  color: #f99117;
  font-weight: 600;
  margin-top: 10px !important;
  /* margin-bottom: 10px !important; */
  margin-left: 4px !important;
}