/* Import Font Awesome styles */
@import "~@fortawesome/fontawesome-free/css/all.css";

.filtercontainer {
  /* margin-bottom: 20px !important; */
  display: 'flex'; 
  flex-Direction: 'row';
  justify-Content:'space-between'
  
}
.button-filter{
  display: flex;
  flex-direction: column;
}
.icon{
  font-size: 30px;
  margin-left: 20px;
  color:  #1b67cc;
}
[title]:hover:after {
  content: attr(title);
  position:initial;
  padding: 4px 8px;
  background-color: #e61010;
  color: #fff;
  border-radius: 4px;
  font-size: 10px;
  white-space: nowrap;
  z-index: 1;
}
[title]:hover {
  cursor: pointer;
}



.paginationContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bottom-para{
  background-color: #3688ec;
  color: #fff;
}

.paginationContainer .pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  /* justify-content: flex-end; */
  float: left;
}

.paginationContainer .pagination .page-item {
  margin: 0 5px;
}

.paginationContainer .pagination .page-link {
  color: #007bff;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 6px 12px;
  border-radius: 3px;
  float: left;
}

.paginationContainer .pagination .page-link:hover {
  background-color: #007bff;
  color: #fff;
}

.paginationContainer .pagination .page-item.active .page-link {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}

.filterSelect{
  /* width: 50px; */
  padding: 6px;
  font-size: small;
  height: fit-content;
  font-size: small;
  margin-top: 10px;
}



.tableContainer {
margin-top: 20px;
/* overflow-x: scroll;
overflow-y: hidden;
scrollbar-width: thin;
scrollbar-color: #1b67cc #e8ebf1;
&::-webkit-scrollbar {
  width: 12px;
}

&::-webkit-scrollbar-thumb {
  background-color: #1b67cc;
}

&::-webkit-scrollbar-track {
  background-color: #e8ebf1;
} */
}




