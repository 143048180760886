@import "~@fortawesome/fontawesome-free/css/all.css";


.cards-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 10px;
    padding: 20px;
    width: fit-content;
}
.para{
    color: #000 !important;
    font-weight: 300;
}
.img-card{
    width: 30vw;

}
.color{
    background-color: #1b67cc !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    font-size: medium !important;
}
.header1{
    display: flex;
    justify-content: space-between;
    align-items:center ;
    background-color:#1b67cc;
    
}

.card { 
    padding: 20px;
    width: 100%; 
    /* height: 50vh;  */
    max-width: 100%; 
    max-height: 48vh; 
    overflow-y: auto; 

    /* Media query for tablets */
    @media (min-width: 768px) {
        max-width: 60vw; 
    }

    /* Media query for laptops/desktops */
    @media (min-width: 1024px) {
        max-width: 50vw; 
    }
}
.card1 {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    width: 42vw;
    /* height: 200px; */
    height: 36vh;
    /* overflow-y: scroll; */
    margin-top: 7px;
    display: flex;
    flex-direction: column;
   
}

.card img {
    width: 100%;
    height: 50%;
    border-radius: 5px;
    margin-bottom: 15px;
    
}

.card label {
    display: block;
    margin-bottom: 10px;
}

.card input[type='text'],
.card select {
    width: calc(100% - 20px);
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.card div {
    margin-top: 0px;
}

.card div input[type='radio'] {
    margin-right: 5px;
}

.card div select {
    width: calc(100% - 20px);
    padding: 2px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
}
/* Assuming `.header` is the class for the header element */

.header {
    /* background-color: #f2f2f2; */
    padding: 20px;
    text-align: center;
}

.header h1 {
    margin: 5px 0;
    font-size: 24px;
}

.header p {
    margin: 5px 0;
    font-size: 16px;
    color: #555;
}
.page-container {
 
    position: relative;
    overflow: hidden;
    height: 100vh;
}   
.page-container.zoomed {
   
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.9);
}
.proof-image {
   
    transition: transform 0.3s ease;
}
.proof-image.zoomed-image {
   
    /* width: 100vw;
    height: auto; */
    max-width: 100%;    
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
}
button {
    margin: 5px;
    padding: 5px 10px;
    font-size: 1.2em;
}
.bottom-para{
    background-color: #3688ec;
    color: #fff;
  }
.image-container {
    position: relative;
    width: 42vw;
    height: 72vh;
    overflow: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    align-content: center;
}
.proof-image {
    display: block;
    transition: transform 0.3s ease-in-out;
    background-size: cover;
    max-width: 100%;
    max-height: 100%;
   
} 
.pdf-container{
    height: 72vh;
    width: 42vw;
    overflow: auto;
}

.zoom-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px; 
    margin-right: 20px; 
    padding: 5px 10px;
    font-size: larger;
}


.status-reject{
    color: red;
    margin-left: 10px;
    font-weight: 600;
}
.status-approve{
    color: green;
    margin-left: 10px;
    font-weight: 600;
}

/* view.css */

/* Loader overlay */

  .cardcontainer{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-left: 18%;
  }
  .check-box{
    display: flex;
    justify-content:space-around;
    flex-direction: row;
    margin-top:20px !important

  }
  .input{
    width: fit-content;
  }
  .address-card{
    height: 100vh;
    border: solid 1px  #ccc;
  border-radius: 5px;
  }
  .reject-reason{
    display: flex;
    flex-direction: column;
  }

  .pdf-embed::-webkit-pdf-toolbar {
    display: none !important;
  }
  /* .address-container{
    background-color: #ccc;
  } */